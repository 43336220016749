/* Copyright 2013 - 2024 Waiterio LLC */
import IS_DEVELOPMENT from './IS_DEVELOPMENT.js'
import IS_STAGING from './IS_STAGING.js'
import IS_TESTING from './IS_TESTING.js'
import IS_BROWSER from './IS_BROWSER.js'
import IS_NODE from './IS_NODE.js'

export default function getGloboAppUrl() {
  let url = 'https://widget.globo.support'

  if (IS_BROWSER && window.GLOBO_WIDGET_URL === '{{{GLOBO_WIDGET_URL}}}') {
    delete window.GLOBO_WIDGET_URL
  }

  if (IS_NODE && process.env.GLOBO_WIDGET_URL === '{{{GLOBO_WIDGET_URL}}}') {
    delete process.env.GLOBO_WIDGET_URL
  }

  if (IS_BROWSER && window.GLOBO_WIDGET_URL) {
    url = `${window.GLOBO_WIDGET_URL}`
  } else if (IS_NODE && process.env.GLOBO_WIDGET_URL) {
    url = process.env.GLOBO_WIDGET_URL
  } else if (IS_STAGING) {
    url = 'https://widget.globo-staging.support'
  } else if (IS_DEVELOPMENT || IS_TESTING) {
    // url = 'https://widget.globo-staging.support';
    // url = 'http://192.168.1.13:11414'
    url = 'http://localhost:11414'
  }

  // if (IS_STAGING || IS_DEVELOPMENT) {
  //   console.debug('Globo.url = ' + url)
  // }

  return url
}
