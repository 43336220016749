/* Copyright 2013 - 2024 Waiterio LLC */

const getScreenWidth = () => {
  let screenWidth

  if (typeof window !== 'undefined') {
    screenWidth = window?.document?.body?.clientWidth
  }

  return screenWidth
}

export default getScreenWidth
