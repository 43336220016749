/* Copyright 2013 - 2024 Waiterio LLC */
import getWidgetUrl from '@globo/shared/getWidgetUrl.js'
import getDeviceType from '@monorepo/shared/device/getDeviceType.js'

// console.log('beginning of widget.js')

const openPathData =
  'M4.584 0c-2.544 0-4.584 2.040-4.584 4.584v10.68c0 2.544 2.040 4.584 4.584 4.584h0.648c0.672 0 0.888 0.216 0.888 0.672v3.264c0 0.216 0.216 0.432 0.864-0.216l3.264-3.288c0.216-0.216 0.672-0.432 0.888-0.432h8.28c2.544 0 4.584-2.040 4.584-4.584v-10.68c0-2.544-2.040-4.584-4.584-4.584h-14.832zM11.976 4.728c1.752-0.024 3.312 1.224 3.384 3 0.048 1.44-0.936 2.76-2.424 3.24v1.032c0 0.528-0.408 0.936-0.936 0.936s-0.936-0.408-0.936-0.936v-1.752c0-0.48 0.384-0.888 0.864-0.936 1.2-0.072 1.584-0.696 1.56-1.512-0.048-0.768-0.552-1.2-1.464-1.2-0.936 0.024-1.392 0.6-1.512 1.056-0.12 0.504-0.624 0.792-1.128 0.672s-0.816-0.624-0.696-1.128c0 0 0 0 0 0 0.336-1.296 1.584-2.448 3.288-2.472zM12 14.136c0.6 0 1.104 0.504 1.104 1.104s-0.504 1.080-1.104 1.080-1.080-0.48-1.080-1.080c0-0.6 0.48-1.104 1.080-1.104z'
const closePathData =
  'M19.008 6.408l-1.416-1.416-5.592 5.592-5.592-5.592-1.416 1.416 5.592 5.592-5.592 5.592 1.416 1.416 5.592-5.592 5.592 5.592 1.416-1.416-5.592-5.592z'

let body = document.getElementsByTagName('body')[0]
let style = document.createElement('style')
style.textContent = `
  :root {
    --color-brand: #386FA4;
  }
  #circle:hover, #circle:focus-visible {
    filter: brightness(115%);
  }

  #iframe {
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    border: 0px;
    z-index: 9001;
  }
  @media (min-width: 576px) {
    #iframe {
      bottom: 84px;
      right: 16px;
      width: 360px;
      height: 640px;
      max-height: calc(100vh - 108px);
      border-radius: 16px;
      box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    }
  }
`
body.appendChild(style)
let iframe = document.createElement('iframe')
iframe.id = 'iframe'
iframe.src = getWidgetUrl()
// iframe.loading = 'lazy'
iframe.style = 'display:none;'
iframe.title = 'support chat'
iframe.setAttribute('aria-label', 'support chat')
iframe.setAttribute('role', 'application')
body.appendChild(iframe)
// console.log('Globo widget: loaded')

window.$globo = window.$globo || []

let circle = document.createElement('button')
circle.id = 'circle'
circle.setAttribute('aria-label', 'customer support chat')
circle.style =
  'border:none;margin:0;padding:0;position:fixed;bottom:16px;right:16px;width:60px;height:60px;border-radius:30px;background-color:var(--color-brand);cursor:pointer;'

function openChat() {
  let iframe = document.getElementById('iframe')
  let path = document.getElementById('path')
  iframe.style.display = 'block'
  path.setAttribute('d', closePathData)
}

function closeChat() {
  let iframe = document.getElementById('iframe')
  let path = document.getElementById('path')
  iframe.style.display = 'none'
  path.setAttribute('d', openPathData)
}

function hideChat() {
  circle.style.display = 'none'
}

function showChat() {
  circle.style.display = 'block'
}

function toggleIframe() {
  let iframe = document.getElementById('iframe')
  if (iframe.style.display === 'none') {
    openChat()
  } else {
    closeChat()
  }
}

function postMessage(message) {
  iframe.contentWindow.postMessage(message, '*')
}

if (window.GLOBO_COLOR_BRAND) {
  document.documentElement.style.setProperty(
    '--color-brand',
    window.GLOBO_COLOR_BRAND,
  )
  window.$globo.push(['setBrandColor', window.GLOBO_COLOR_BRAND])
}

if (window.GLOBO_HIDE_CHAT) {
  hideChat()
}

iframe.addEventListener('load', () => {
  if (window.GLOBO_PROJECT_ID) {
    window.$globo.push(['setWebsiteId', window.GLOBO_PROJECT_ID])
  }

  if (window.GLOBO_WEBSITE_ID) {
    window.$globo.push(['setWebsiteId', window.GLOBO_WEBSITE_ID])
  }

  if (window.GLOBO_COLOR_BRAND) {
    window.$globo.push(['setColorBrand', window.GLOBO_COLOR_BRAND])
  }

  if (window.GLOBO_VERSION) {
    window.$globo.push(['setVersion', window.GLOBO_VERSION])
  }

  if (window.GLOBO_HIDE_CHAT) {
    hideChat()
  }

  let deviceType = getDeviceType()

  window.$globo.push(['setDeviceType', deviceType])

  setTimeout(() => {
    if (window.$globo) {
      for (let i = 0; i < window.$globo.length; i += 1) {
        let parameters = window.$globo[i]
        postMessage(parameters)
        // console.log('onLoad posted message', parameters)
      }
    }

    window.$globo = new Proxy(window.$globo, {
      set(target, prop, value) {
        // console.log('ProxyHandler set', target, prop, value)
        postMessage(value)
        // console.log('Proxy posted message', value)
        return true
      },
    })

    // console.log('just defined window.$globo in widget.js')
  }, 1000)
  // wait 1 second so that IframeMessenger gets loaded on slower Safari
})

let svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
svg.setAttribute('viewBox', '0 0 24 24')
svg.setAttribute('style', 'fill:white;margin:16px')
svg.setAttribute('width', 28)
svg.setAttribute('height', 28)

circle.onclick = toggleIframe
let path = document.createElementNS('http://www.w3.org/2000/svg', 'path')
path.id = 'path'
path.setAttribute('d', openPathData)
svg.appendChild(path)
circle.appendChild(svg)
body.appendChild(circle)

window.addEventListener(
  'message',
  message => {
    // console.log('window.addEventListener message', message)
    if (message?.data?.type === 'closeChat') {
      closeChat()
    } else if (message?.data?.type === 'openChat') {
      openChat()
    } else if (message?.data?.type === 'hideChat') {
      hideChat()
    } else if (message?.data?.type === 'showChat') {
      showChat()
    }
  },
  false,
)
