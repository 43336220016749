/* Copyright 2013 - 2024 Waiterio LLC */

const getScreenHeight = () => {
  let screenHeight

  if (typeof window !== 'undefined') {
    screenHeight = window?.document?.body?.clientHeight
  }

  return screenHeight
}

export default getScreenHeight
