/* Copyright 2013 - 2024 Waiterio LLC */
import getScreenHeight from './getScreenHeight.js'
import getScreenWidth from './getScreenWidth.js'
import getUserAgent from './getUserAgent.js'

const getDeviceType = params => {
  params ||= {}
  let { userAgent, screenWidth, screenHeight } = params
  userAgent ||= getUserAgent()
  screenHeight ||= getScreenHeight()
  screenWidth ||= getScreenWidth()
  let deviceType = 'unknown'

  if (screenWidth < 576) {
    deviceType = 'smartphone'
  } else if (screenWidth < 1248) {
    deviceType = 'tablet'
  } else if (screenWidth >= 1248) {
    deviceType = 'desktop'
  }

  // alert(
  //   `screenWidth: ${screenWidth} screenHeight: ${screenHeight} type: ${deviceType}`,
  // )

  return deviceType
}

export default getDeviceType
