/* Copyright 2013 - 2024 Waiterio LLC */

const getUserAgent = () => {
  let userAgent = ''

  if (typeof window !== 'undefined') {
    userAgent = window?.navigator?.userAgent ?? ''
  }

  return userAgent
}

export default getUserAgent
